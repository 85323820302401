import { SectionLayoutType } from '@/shared/models/Section';
import { ISectionComponentProps } from '../utils';
import Box from '@material-ui/core/Box'
import { Separator } from '@/shared/components/Separator/Separator';

export default function DividerContainer(props: ISectionComponentProps) {
    const { section }= props
    if(section.layout === SectionLayoutType.SEPARATOR) {
        return (
            <Box py={4}>
                <Separator size={section?.content?.size}/>
            </Box>
        )
    }
    const py= { 
        small: 5,
        medium: 7,
        large: 8
    }[section.content.size]
    return (
        <Box py={py}/>
    )
}